import React, { useContext } from 'react';
import { FullScreenLoading as SharedFullScreenLoading, FullScreenLoadingProps } from '@apari-shared/components';
import { AppContext } from 'context/AppContext';

const FullScreenLoading: React.FC<Partial<FullScreenLoadingProps>> = ({ logo, ...rest }) => {
    const { whitelabelledApp } = useContext(AppContext);

    return <SharedFullScreenLoading logo={logo || whitelabelledApp.assets.fullScreenLoading.logo} imgStyle={{ height: 25 }} {...rest} />;
};
export default FullScreenLoading;
